<template>
  <section v-if="slides && slides.length" class="home-additional">
    <div class="home-additional__inner">
      <div class="container-padding">
        <h2 class="home-additional__title">Дополнительная информация</h2>
      </div>
      <div class="swiper home-additional__content" ref="items">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(slide, i) in slides" :key="i">
            <AdditionalSlide :slide="slide" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AdditionalSlide from "./AdditionalSlide.vue";

export default {
  name: "HomeAdditionalComponent",
  data: () => ({
    swiper: null,
    autoPlayDuration: 10, // секунд
  }),
  computed: {
    slides() {
      return this.$store.state.home.additional_sliders?.images;
    },
  },
  mounted() {
    require(["swiper"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, {
        slidesPerView: 3.7,
        loop: true,
        centeredSlides: true,
        slidesPerColumn: 1,
        spaceBetween: 20,
        breakpoints: {
          0: {
            slidesPerView: 2.3,
            spaceBetween: 10,
          },
          500: {
            slidesPerView: 2.7,
            spaceBetween: 15,
          },
          700: {
            slidesPerView: 3.3,
            spaceBetween: 20,
          },
          1000: {
            slidesPerView: 3.7,
            spaceBetween: 20,
          },
        },
      });
    });
  },
  components: {
    AdditionalSlide,
  },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css";
.home-additional {}
</style>
