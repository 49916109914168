<template>
  <article v-if="slide" class="additional-slide">
    <a :href="slide.link" target="_blank">
      <ImgComponent v-if="slide && slide.img" :img="slide.img" class="additional-slide__image" />
    </a>
  </article>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "AdditionalSlide",
  props: {
    slide: Object,
  },
  components: {
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.additional-slide {

  &__image {
    border-radius 3px
    max-height 400px
  }
}
</style>
