<template>
  <main class="home-page page">
    <HomeBannerComponent />
    <HomeAnnouncementsComponent />
    <HomeMediaComponent />
    <HomeLinksComponent />
    <HomeAdditionalComponent />
    <HomeTelegramComponent />
    <HomeGovernmentComponent />
  </main>
</template>

<script>
import HomeBannerComponent from "./components/HomeBannerComponent.vue";
import HomeAnnouncementsComponent from "./components/HomeAnnouncementsComponent.vue";
import HomeMediaComponent from "./components/HomeMediaComponent.vue";
import HomeAdditionalComponent from "./components/HomeAdditionalComponent.vue";
import HomeGovernmentComponent from "./components/HomeGovernmentComponent.vue";
import HomeTelegramComponent from "./components/HomeTelegramComponent.vue";
import HomeLinksComponent from "./components/HomeLinksComponent.vue";
import HOME_PAGE from "gql/pages/HomePage.graphql";

export default {
  name: "HomePage",
  metaInfo: {
    title: "Главная",
  },
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: HOME_PAGE,
      })
      .then(({ data }) => {
        store.dispatch("home/save", data);
      });
  },
  components: {
    HomeGovernmentComponent,
    HomeLinksComponent,
    HomeTelegramComponent,
    HomeAdditionalComponent,
    HomeMediaComponent,
    HomeAnnouncementsComponent,
    HomeBannerComponent,
  },
};
</script>

<style lang="stylus">
.home-page {
  position relative
  display grid
  grid-gap 70px
  +below(700px) {
    grid-gap 40px
  }
}
</style>
