<template>
  <section v-if="media && media.length" class="home-media">
    <div class="container-padding">
      <div class="home-media__inner">
        <h2 class="home-media__title">СМИ о нас</h2>
        <div class="home-media__content">
          <MediaCardComponent v-for="(media, i) in media" :key="i" :media="media" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MediaCardComponent from "components/MediaCardComponent.vue";

export default {
  name: "HomeMediaComponent",
  computed: {
    media() {
      return this.$store.state.home.media.data;
    },
  },
  components: {
    MediaCardComponent,
  },
};
</script>

<style lang="stylus">
.home-media {

  &__content {
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 30px 20px
    +below(1000px) {
      grid-template-columns 1fr
    }
  }

  &__item {
    display grid
    grid-gap 20px
    background var(--white)
    border-radius 10px
    overflow hidden
    height 100%
    box-shadow: 0 5px 10px rgba(153, 108, 61, 0.2);
  }

  &__item-image {
    height 250px
    max-height 250px
  }

  &__item-text {
    display grid
    grid-gap 15px
    padding 15px
  }

  &__item-title {
    font-size 1.625rem
    font-weight 500
    line-height 30px
    color var(--black_2)
  }

  &__item-subtitle {
    font-size .875rem
    line-height 16px
    color var(--gray_3)
  }

  &__item-date {
    display flex
    justify-content flex-end
    font-size .875rem
    line-height 16px
    color var(--black_2)
  }
}
</style>
