<template>
  <section v-if="slides && slides.length" class="home-government">
    <div class="home-government__inner">
      <div class="swiper home-government__content" ref="items">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(slide, i) in slides" :key="i">
            <GovernmentSlide :slide="slide" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import GovernmentSlide from "./GovernmentSlide.vue";

export default {
  name: "HomeGovernmentComponent",
  data: () => ({
    swiper: null,
    autoPlayDuration: 10, // секунд
  }),
  computed: {
    slides() {
      return this.$store.state.home.government_sliders?.images;
    },
  },
  mounted() {
    require(["swiper"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, {
        slidesPerView: 3.7,
        loop: true,
        centeredSlides: true,
        slidesPerColumn: 1,
        spaceBetween: 20,
        breakpoints: {
          0: {
            slidesPerView: 1.1,
            spaceBetween: 10,
          },
          360: {
            slidesPerView: 1.3,
            spaceBetween: 10,
          },
          450: {
            slidesPerView: 1.6,
            spaceBetween: 10,
          },
          600: {
            slidesPerView: 2.3,
            spaceBetween: 10,
          },
          750: {
            slidesPerView: 2.7,
            spaceBetween: 15,
          },
          950: {
            slidesPerView: 3.3,
            spaceBetween: 20,
          },
          1100: {
            slidesPerView: 3.7,
            spaceBetween: 20,
          },
        },
      });
    });
  },
  components: {
    GovernmentSlide,
  },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css";
.home-government {}
</style>
