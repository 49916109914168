<template>
  <section v-if="data" class="home-telegram">
    <div class="container-padding">
      <div class="home-telegram__inner">
        <a v-if="data && data.link" :href="data.link" target="_blank" class="home-telegram__content">
          <img alt="иконка telegram главы РД" src="static/svg/telegram.svg" class="home-telegram__icon" />
          <span v-if="data && data.title" class="home-telegram__title">{{ data.title }}</span>
          <ImgComponent v-if="data && data.img" :img="data.img" class="home-telegram__icon" />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "HomeTelegramComponent",
  computed: {
    data() {
      return this.$store.state.home.chief_telegram;
    },
  },
  components: {
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.home-telegram {

  &__inner {
    display flex
    justify-content center
  }

  &__content {
    padding 15px
    border-radius 15px
    color var(--white)
    background var(--main)
    //height 105px
    max-height 105px
    display flex
    align-items center
    justify-content center
    gap 20px
    max-width 585px
    +below(500px) {
      gap 10px
    }
  }

  &__icon {
    max-width 65px
    max-height 65px
    +below(500px) {
      max-width 45px
      max-height 45px
    }
  }

  &__title {
    text-align center
    +below(500px) {
      font-size .75rem
      line-height 1.375rem
    }
  }
}
</style>
