<template>
  <article v-if="slide" class="government-slide">
    <a :href="slide.link" target="_blank" class="government-slide__inner">
      <ImgComponent :img="slide.img" class="government-slide__image" />
      <div class="government-slide__text">
        <span v-if="slide.title" class="government-slide__title">{{ slide.title }}</span>
        <span v-if="slide.subtitle" class="government-slide__link">{{ slide.subtitle }}</span>
      </div>
    </a>
  </article>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "GovernmentSlide",
  props: {
    slide: Object,
  },
  components: {
    ImgComponent,
  },
};
</script>

<style lang="stylus">
@import 'mixins/maxlines.styl'
.government-slide {
  max-height 130px
  height 130px
  background var(--white)
  padding 10px
  border-radius 5px
  //box-shadow: 0 5px 10px rgba(153, 108, 61, 0.2);

  &__inner {
    display flex
    gap 30px
  }

  &__text {
    display grid
    grid-gap 5px
  }

  &__title {
    font-size 1.125rem
    font-weight 500
    line-height 1.625rem
    maxlines(3)
    +below(1360px) {
      font-size 1rem
    }
  }

  &__link {
    font-size 1rem
    line-height 26px
    color var(--gray_3)
    +below(1360px) {
      font-size .875rem
      line-height 1.5rem
    }
  }

  &__image {
    border-radius 3px
    max-width 110px
    max-height 110px
  }
}
</style>
