<template>
  <section v-if="slides && slides.length" class="home-banner">
    <div class="swiper home-banner__content" ref="items">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(slide, i) in slides" :key="i">
          <BannerSlide :slide="slide" />
        </div>
      </div>
      <div class="home-banner__buttons">
        <button class="btn btn--white btn--circle" @click="swiper.slidePrev()">
          <IconComponent name="arrow-left-line" />
        </button>
        <button class="btn btn--white btn--circle" @click="swiper.slideNext()">
          <IconComponent name="arrow-right-line" />
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import BannerSlide from "./BannerSlide.vue";
import IconComponent from "components/IconComponent.vue";
export default {
  name: "HomeBannerComponent",
  data: () => ({
    swiper: null,
    autoPlayDuration: 10, // секунд
  }),
  computed: {
    slides() {
      return this.$store.state.home.main_sliders?.images;
    },
  },
  mounted() {
    require(["swiper"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, {
        loop: true,
      });
    });
  },
  components: {
    IconComponent,
    BannerSlide,
  },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css";
.home-banner {
  position relative
  width 100%
  margin 0 auto
  height 700px
  +below(750px) {
    height 470px
  }

  &__content {
    absolute left top
    width 100%
    height 100%
  }

  &__buttons {
    display flex
    align-items center
    gap 10px
    position: absolute;
    bottom 50px
    right 50px
    z-index 2
    +below(1000px) {
      right 20px
      bottom 20px
    }
  }
}
</style>
