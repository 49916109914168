<template>
  <section v-if="slides && slides.length" class="home-announcements">
    <div class="home-announcements__inner">
      <div class="container-padding">
        <h2 class="home-announcements__title">Анонсы</h2>
      </div>
      <div class="swiper home-announcements__content" ref="items">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(slide, i) in slides" :key="i">
            <AnnouncementSlide :slide="slide" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AnnouncementSlide from "./AnnouncementSlide.vue";

export default {
  name: "HomeAnnouncementsComponent",
  data: () => ({
    swiper: null,
    autoPlayDuration: 10, // секунд
  }),
  computed: {
    slides() {
      return this.$store.state.home.announcement_sliders;
    },
  },
  mounted() {
    require(["swiper"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, {
        slidesPerView: 4.1,
        slidesPerColumn: 1,
        spaceBetween: 20,
        breakpoints: {
          0: {
            slidesPerView: 1.1,
            spaceBetween: 10,
          },
          450: {
            slidesPerView: 1.4,
            spaceBetween: 15,
          },
          510: {
            slidesPerView: 1.7,
            spaceBetween: 15,
          },
          610: {
            slidesPerView: 2.1,
            spaceBetween: 15,
          },
          700: {
            slidesPerView: 2.3,
            spaceBetween: 15,
          },
          800: {
            slidesPerView: 2.6,
            spaceBetween: 20,
          },
          1000: {
            slidesPerView: 3.1,
            spaceBetween: 20,
          },
          1300: {
            slidesPerView: 4.1,
            spaceBetween: 20,
          },
        },
      });
    });
  },
  components: { AnnouncementSlide },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css";
.home-announcements {

  .swiper-slide {
    height 450px

    &:first-child {
      margin-left calc((100vw - 1400px) / 2)
      +below(1460px) {
        margin-left 30px
      }
      +below(700px) {
        margin-left 15px
      }
    }
  }

  &__content {
    width 100%
    height 100%
    padding-right 150px
    +below(1600px) {
      padding-right 120px
    }
    +below(1500px) {
      padding-right 70px
    }
    +below(1400px) {
      padding-right 50px
    }
    +below(700px) {
      padding-right 30px
    }
  }
}
</style>
